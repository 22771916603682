body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Gilroy-Regular',
    sans-serif; */
  font-family: 'Gilroy-Regular', 'Gilroy-Black', 'Gilroy-BlackItalic', 'Gilroy-Bold', 
  'Gilroy-BoldItalic', 'Gilroy-ExtraBold', 'Gilroy-ExtraBoldItalic', 'Gilroy-Heavy', 'Gilroy-HeavyItalic', 
  'Gilroy-Light', 'Gilroy-LightItalic', 'Gilroy-Medium', 'Gilroy-MediumItalic', 'Gilroy-RegularItalic', 'Gilroy-SemiBold', 
  'Gilroy-SemiBoldItalic', 'Gilroy-Thin', 'Gilroy-ThinItalic',  'Gilroy-UltraLight', 'Gilroy-UltraLightItalic', 
  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

