@property --num {
    syntax: '<integer>';
    initial-value: 800;
    inherits: false;
  }
  
  .clientNumberAnimate {
    transition: --num 1s;
    counter-reset: num var(--num);
  }
  .clientNumberAnimate:hover {
    --num: 800;
  }
  .clientNumberAnimate::after {
    content: counter(num);
  }

  @property --eNum {
    syntax: '<integer>';
    initial-value: 10;
    inherits: false;
  }
  
  .employeeNumberAnimate {
    transition: --eNum 1s;
    counter-reset: eNum var(--eNum);
  }
  .employeeNumberAnimate:hover {
    --eNum: 10;
  }
  .employeeNumberAnimate::after {
    content: counter(eNum);
  }

  @property --pNum {
    syntax: '<integer>';
    initial-value: 5;
    inherits: false;
  }
  
  .programsNumberAnimate {
    transition: --pNum 1s;
    counter-reset: pNum var(--pNum);
  }
  .programsNumberAnimate:hover {
    --pNum: 5;
  }
  .programsNumberAnimate::after {
    content: counter(pNum);
  }

  @property --proNum {
    syntax: '<integer>';
    initial-value: 12;
    inherits: false;
  }
  
  .projectsNumberAnimate {
    transition: --proNum 1s;
    counter-reset: proNum var(--proNum);
  }
  .projectsNumberAnimate:hover {
    --proNum: 12;
  }
  .projectsNumberAnimate::after {
    content: counter(proNum);
  }