.App {
  text-align: center;
  background-color: black;
  color: white;
  font-family: GILROY-Medium;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Gilroy-Regular";   /*Can be any text*/
  src: local("Gilroy-Regular"),
    url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Black";   /*Can be any text*/
  src: local("Gilroy-Black"),
    url("./assets/fonts/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-BlackItalic";   /*Can be any text*/
  src: local("Gilroy-BlackItalic"),
    url("./assets/fonts/Gilroy-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";   /*Can be any text*/
  src: local("Gilroy-Bold"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-BoldItalic";   /*Can be any text*/
  src: local("Gilroy-BoldItalic"),
    url("./assets/fonts/Gilroy-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";   /*Can be any text*/
  src: local("Gilroy-ExtraBold"),
    url("./assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBoldItalic";   /*Can be any text*/
  src: local("Gilroy-ExtraBoldItalic"),
    url("./assets/fonts/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Heavy";   /*Can be any text*/
  src: local("Gilroy-Heavy"),
    url("./assets/fonts/Gilroy-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-HeavyItalic";   /*Can be any text*/
  src: local("Gilroy-HeavyItalic"),
    url("./assets/fonts/Gilroy-HeavyItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";   /*Can be any text*/
  src: local("Gilroy-Light"),
    url("./assets/fonts/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-LightItalic";   /*Can be any text*/
  src: local("Gilroy-LightItalic"),
    url("./assets/fonts/Gilroy-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";   /*Can be any text*/
  src: local("Gilroy-Medium"),
    url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-MediumItalic";   /*Can be any text*/
  src: local("Gilroy-MediumItalic"),
    url("./assets/fonts/Gilroy-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-RegularItalic";   /*Can be any text*/
  src: local("Gilroy-RegularItalic"),
    url("./assets/fonts/Gilroy-RegularItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";   /*Can be any text*/
  src: local("Gilroy-SemiBold"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBoldItalic";   /*Can be any text*/
  src: local("Gilroy-SemiBoldItalic"),
    url("./assets/fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Thin";   /*Can be any text*/
  src: local("Gilroy-Thin"),
    url("./assets/fonts/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ThinItalic";   /*Can be any text*/
  src: local("Gilroy-ThinItalic"),
    url("./assets/fonts/Gilroy-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-UltraLight";   /*Can be any text*/
  src: local("Gilroy-UltraLight"),
    url("./assets/fonts/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-UltraLightItalic";   /*Can be any text*/
  src: local("Gilroy-UltraLightItalic"),
    url("./assets/fonts/Gilroy-UltraLightItalic.ttf") format("truetype");
}